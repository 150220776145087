@use '@angular/material' as mat;
@mixin ap-supplierpartners-theme($theme, $primary, $accent, $darkmode) {
    @if $darkmode {
        .expand-icon-float {
            background-color: #303030;
        }
        .sp-wrapper {
            border: #616161 2px solid;
        }
    } @else {
        .mat-icon.expand-icon-float {
            background-color: mat.get-theme-color($theme, neutral, 99);
        }
        .sp-wrapper {
            border: #b1b1b1 2px solid;
        }
    }
}
