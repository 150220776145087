@use '@angular/material' as mat;

@mixin ap-roast-theme($theme, $primary, $accent, $darkmode) {

    @if ($darkmode) {

        .mat-expansion-panel-header.mat-expanded.reconciled,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded.reconciled:focus,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded.reconciled:hover,
        .mat-expansion-panel.reconciled::before {
            background: mat.get-theme-color($theme, neutral, 35);
        }
    }

    @else {

        .mat-expansion-panel-header.mat-expanded.reconciled,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded.reconciled:focus,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded.reconciled:hover,
        .mat-expansion-panel.reconciled::before {
            background: mat.get-theme-color($theme, neutral, 70);
        }
    }
}