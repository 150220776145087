@mixin ap-welcome-theme($theme, $primary, $accent, $darkmode) {
    @if $darkmode {
        .main-row.back {
            background-color: #424242;
        }
        .main-explanation {
            color: $primary;
        }
        .main-features {
            color: rgb(255, 255, 255, 0.6);
        }
    } @else {
        .main-row.back {
            background-color: lightgrey;
        }
        .main-explanation {
            color: $primary;
        }
        .main-features {
            color: #616161;
        }
    }
}