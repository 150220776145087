@use '@angular/material' as mat;

@mixin ap-toolbar-theme($theme, $primary, $accent, $darkmode) {

    .mat-toolbar {
        @if $darkmode {
            background-color: #424242;
        }

        @else {
            background-color: $primary;
        }
    }

    .mat-toolbar.readOnly {
        background: #adadad;
    }

    .mat-toolbar.accent {
        background-color: $accent;
    }

    .toolbar-normal-color,
    .toolbar-normal-color a,
    .account-selector .mat-mdc-select,
    .account-selector .mat-mdc-select-arrow,
    .toolbar-normal-color .account-selector .mat-focused .mat-mdc-select-arrow,
    .mat-mdc-select .toolbar-normal-color,
    .mat-mdc-select-arrow .toolbar-normal-color {
        @if $darkmode {
            // color: mat.m2-get-color-from-palette(map-get($theme, foreground), secondary-text);
            color: mat.get-theme-color($theme, secondary-fixed);
        }

        @else {
            color: mat.get-theme-color($theme, neutral, 90);
        }
    }

    .mat-toolbar .mat-toolbar-row img.plusicon:active,
    .mat-toolbar .mat-toolbar-row img.plusicon:focus,
    .mat-toolbar .mat-toolbar-row img.plusicon:hover {
        @if $darkmode {
            brightness: 1.8;
            opacity: 1;
            -moz-opacity: 1;
            filter: alpha(opacity=100);
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
        }

        @else {
            opacity: 0.78;
            -moz-opacity: 0.78;
            filter: alpha(opacity=78);
        }
    }

    .mat-toolbar .mat-toolbar-row img.plusicon {
        @if $darkmode {
            opacity: 0.82;
            -moz-opacity: 0.82;
            filter: alpha(opacity=82);
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
        }
    }

    mat-toolbar:not(.accent) .expired {
        color: $accent;
    }
}