@use '@angular/material' as mat;

@mixin ap-coffee-theme($theme, $primary, $accent, $darkmode) {
    .new-place-part {
        @if $darkmode {
            background-color: mat.get-theme-color($theme, neutral, 30);
        }

        @else {
            background-color: mat.get-theme-color($theme, primary, 95);
        }
    }

    // .paneltitle.hidden-label, .head-label.hidden-label {
    //     color: $accent;
    // }
    .mat-expansion-panel.hidden-label:not(.mat-expanded)::before {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0px;
        content: "";
        background: $accent;
        width: 13px;
        z-index: 1;
    }

    .mat-expansion-panel-header.mat-expanded.hidden-label,
    .mat-expansion-panel-header.mat-expanded.hidden-label:focus,
    .mat-expansion-panel-header.mat-expanded.hidden-label:hover {
        border: 2px solid $accent;
    }

    .highlight-important {
        margin-bottom: 10px;

        @if $darkmode {
            background-color: mat.get-theme-color($theme, neutral, 30);
        }

        @else {
            background-color: mat.get-theme-color($theme, primary, 95);
        }
    }

    @if $darkmode {
        .beans-table-select {
            border-color: mat.get-theme-color($theme, neutral, 70);
        }
    }

    @else {
        .beans-table-select {
            border-color: #eeeeee;
        }
    }
}