@use '@angular/material' as mat;

@mixin ap-storestock-theme($theme, $primary, $accent, $darkmode) {
    .clipped-label {
        @if $darkmode {
            color: mat.get-theme-color($theme, primary, 80);
        }

        @else {
            color: mat.get-theme-color($theme, primary, 35);
        }
    }
}