@use '@angular/material' as mat;
@mixin ap-beansreport-theme($theme, $primary, $accent, $darkmode) {
    @if $darkmode {
        .mat-expanded .head-label, .mat-expanded.missing-transactions .head-label {
            color: mat.get-theme-color($theme, primary, 80);
        }
    } @else {
        .mat-expanded .head-label, .mat-expanded.missing-transactions .head-label {
            color: mat.get-theme-color($theme, primary, 30);
        }
    }

    .mat-expansion-panel-header.mat-expanded.missing-transactions,
    .mat-expansion-panel-header.mat-expanded.missing-transactions:focus,
    .mat-expansion-panel-header.mat-expanded.missing-transactions:hover {
        border: 2px solid $accent;
    }
    .mat-expansion-panel.missing-transactions::before {
        background: $accent;
    }
}
