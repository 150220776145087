@use '@angular/material' as mat;

@mixin ap-app-theme($theme, $primary, $accent, $darkmode) {

    .footer {
        @if $darkmode {
            background-color: #212121;
        }

        @else {
            background-color: $primary;
        }
    }

    .mat-icon.mat-mdc-list-item-icon.menu-icon {
        color: mat.get-theme-color($theme, on-surface);
    }

    .footer {
        color: mat.get-theme-color($theme, neutral, 70);
    }

    .footer a {
        color: mat.get-theme-color($theme, neutral, 70);
    }

    .mat-mdc-nav-list .mat-mdc-list-item.activeMenu,
    .mat-mdc-nav-list .mat-mdc-list-item.activeMenu:focus {
        @if $darkmode {
            background-color: mat.get-theme-color($theme, neutral, 40);
            color: white;
        }

        @else {
            background-color: mat.get-theme-color($theme, primary, 80);
        }
    }
}