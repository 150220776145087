@use '@angular/material' as mat;
@mixin ap-roast-blend-edit-theme($theme, $primary, $accent, $darkmode) {

    // hard to read:
    // .replace-line {
    //     color: $accent;
    // }
    .av-comment {
        // color: mat.m2-get-color-from-palette(map-get($theme, foreground), hint-text);
        color: mat.get-theme-color($theme, on-surface);
    }
}
