@mixin ap-usersettings-theme($theme, $primary, $accent, $darkmode) {

    .warning {
        color: $accent;
    }

    .mat-icon.helpicon.bigicon {
        color: mat.get-theme-color($theme, on-surface);
    }

    .incompatible-setting {
        background-color: $accent;
    }
}