@use '@angular/material' as mat;

@mixin ap-roastreport-theme($theme, $primary, $accent, $darkmode) {
    .mat-expanded .head-label {
        color: mat.get-theme-color($theme, primary, 30);
    }

    .head-label-discarded {
        color: mat.get-theme-color($theme, tertiary, 50);
    }
}