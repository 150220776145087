@use '@angular/material' as mat;
@use './m3-theme.scss';
// @import './theme_vars.scss';

@include mat.all-component-typographies();
@include mat.core();

// https://material.io/design/color/#tools-for-picking-colors
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


// generated with http://mcg.mbitson.com/#!?plus=%2300897b
// used as described in https://blog.thoughtram.io/angular/2017/05/23/custom-themes-with-angular-material.html#understanding-mat-palette
// $md-plus-theme: mat.define-light-theme($md-plus-primary, $md-plus-accent, $md-plus-warn);
// @include mat.all-component-themes($md-plus-theme);

@import '../../modules/account/user-settings.component-themed.scss';
@import '../../modules/report/customs/customsreports.component-themed.scss';
@import '../../modules/report/reporting.component-themed.scss';
@import '../../modules/report/beans/beansreport.component-themed.scss';
@import '../../modules/report/roasts/roastreport.component-themed.scss';
@import '../../modules/roast/roast.component-themed.scss';
@import '../../modules/roast/bleinput.component-themed.scss';
@import '../../modules/blend/roast-blend-edit.component-themed.scss';
@import '../../modules/transaction/dialogs/correct-dialog.component-themed.scss';
@import '../../modules/store/stores.component-themed.scss';
@import '../../modules/stock/store-stock.component-themed.scss';
@import '../../modules/coffee/coffee.component-themed.scss';
@import '../../modules/contact/supplierpartners.component-themed.scss';
@import '../../modules/contact/supplier-coupon.component-themed.scss';
@import '../../modules/frame/welcome.component-themed.scss';
@import '../../modules/ui/ap-toolbar/ap-toolbar.component-themed.scss';
@import '../../modules/graph/roast/energytotal-widget-themed.component.scss';
@import '../../modules/reminders/renamemachinenames-dialog.component-themed.scss';
@import '../../modules/reminders/reminder.component-themed.scss';
@import './dialogs-themed.scss';
@import './matexpandable-themed.scss';
@import './styles-themed.scss';
@import '../../app.component-themed.scss';

// #mainappdiv {
html {
    $isFirstCall: true;

    @mixin ap-theme($theme, $darkmode) {
        @if $isFirstCall {
            $isFirstCall: false;
            @include mat.all-component-themes($theme);
        }

        @else {
            @include mat.all-component-colors($theme);
        }

        $primary: mat.get-theme-color($theme, primary);
        $accent: mat.get-theme-color($theme, tertiary);

        @if $darkmode {
            // avoid color being swapped with #e687a8 in dark mode
            $accent: #cc0f50;
            --mat-app-background-color: #303030;
            --mat-app-background: #303030;
            --mat-sidenav-content-background-color: #303030;
            --mat-sidenav-container-background-color: #424242;
            --mat-paginator-container-background-color: var(--mat-sidenav-container-background-color);
            --mdc-outlined-card-container-color: var(--mat-sidenav-container-background-color);
            --mdc-dialog-container-color: var(--mat-sidenav-container-background-color);
            --mat-table-background-color: var(--mat-sidenav-container-background-color);
            --mat-tab-header-active-label-text-color: #{mat.get-theme-color($theme, primary, 80)};
            --mat-stepper-container-color: #{mat.get-theme-color($theme, neutral, 30)};
            --mat-stepper-header-icon-foreground-color: #{mat.get-theme-color($theme, neutral, 25)};
            --mat-stepper-header-icon-foreground-color: var(--mat-sidenav-content-text-color);
        }

        @else {
            --mdc-outlined-card-container-color: #fafafa;
            --mat-sidenav-content-background-color: #fafafa;
            --mat-sidenav-container-background-color: white;
            --mat-table-background-color: var(--mat-sidenav-container-background-color);
            --mat-tab-header-inactive-label-text-color: #{mat.get-theme-color($theme, neutral, 25)}; // 0 0 0 0.6
            --mdc-list-list-item-label-text-color: #{mat.get-theme-color($theme, neutral, 20)}; // 0 0 0 0.87
            --mat-expansion-header-description-color: #{mat.get-theme-color($theme, neutral, 35)}; // 0 0 0 0.54
            --mat-tab-header-active-label-text-color: #{mat.get-theme-color($theme, primary, 30)};
        }

        --mat-expansion-container-background-color: var(--mat-sidenav-container-background-color);
        --mat-paginator-container-background-color: #00000000;

        --mat-expansion-header-text-size: 14px;
        --mat-expansion-container-text-size: 14px;
        --mat-toolbar-standard-height: 45px;
        --mdc-list-list-item-one-line-container-height: 56px;
        --mat-toolbar-mobile-height: 37px;
        --mat-form-field-container-height: 40px;
        --mat-select-trigger-text-size: 14px;
        --mat-sidenav-container-width: auto;
        --mat-text-button-with-icon-horizontal-padding: 23px;
        --mat-badge-container-overlap-offset: -7px;

        --mdc-chip-flat-selected-outline-width: 1px;
        --mdc-chip-outline-width: 0;

        @include ap-usersettings-theme($theme, $primary, $accent, $darkmode);
        @include ap-customsreports-theme($theme, $primary, $accent, $darkmode);
        @include ap-dialogs-theme($theme, $primary, $accent, $darkmode);
        @include ap-reporting-theme($theme, $primary, $accent, $darkmode);
        @include ap-styles-theme($theme, $primary, $accent, $darkmode);
        @include ap-app-theme($theme, $primary, $accent, $darkmode);
        @include ap-roast-theme($theme, $primary, $accent, $darkmode);
        @include ap-roast-blend-edit-theme($theme, $primary, $accent, $darkmode);
        @include ap-coffee-theme($theme, $primary, $accent, $darkmode);
        @include ap-beansreport-theme($theme, $primary, $accent, $darkmode);
        @include ap-roastreport-theme($theme, $primary, $accent, $darkmode);
        @include ap-storestock-theme($theme, $primary, $accent, $darkmode);
        @include ap-correctdialog-theme($theme, $primary, $accent, $darkmode);
        @include ap-matexpandable-theme($theme, $primary, $accent, $darkmode);
        @include ap-supplierpartners-theme($theme, $primary, $accent, $darkmode);
        @include ap-suppliercoupon-theme($theme, $primary, $accent, $darkmode);
        @include ap-toolbar-theme($theme, $primary, $accent, $darkmode);
        @include ap-store-theme($theme, $primary, $accent, $darkmode);
        @include ap-welcome-theme($theme, $primary, $accent, $darkmode);
        @include ap-energy-widget-theme($theme, $primary, $accent, $darkmode);
        @include ap-bleinput-theme($theme, $primary, $accent, $darkmode);
        @include ap-renamemachinenames-dialog-theme($theme, $primary, $accent, $darkmode);
        @include ap-reminders-theme($theme, $primary, $accent, $darkmode);
    }

    // @function md-plus-dark-theme-foreground($color) {
    //     @return (base: $color,
    //         divider: rgba(white, 0.12),
    //         dividers: rgba(white, 0.12),
    //         disabled: rgba($color, 0.38),
    //         disabled-button: rgba($color, 0.38),
    //         disabled-text: rgba($color, 0.38),
    //         hint-text: rgba($color, 0.38),
    //         secondary-text: rgba($color, 0.54),
    //         icon: rgba($color, 0.54),
    //         icons: rgba($color, 0.54),
    //         text: rgba($color, 0.87),
    //         slider-off: rgba($color, 0.26),
    //         slider-off-active: rgba($color, 0.38),
    //         slider-min: rgba($color, 0.38));
    // }

    // @mixin use-dark-mode() {
    //     $white-foreground: md-plus-dark-theme-foreground(white);
    //     $md-plus-dark-theme-custom: map-merge(m3-theme.$dark-theme, (foreground: $white-foreground));
    //     @include ap-theme($md-plus-dark-theme-custom, true, false);
    // }

    // include light theme by default
    color-scheme: light;
    @include ap-theme(m3-theme.$light-theme, false);

    .force-dark-mode {
        //     @include ap-theme(m3-theme.$dark-theme, true, true); 
        // @include use-dark-mode();
        color-scheme: dark;
        @include ap-theme(m3-theme.$dark-theme, true);
    }

    // .force-light-mode {
    //     @include ap-theme(m3-theme.$light-theme, false);
    // }

    .auto-mode {
        @media (prefers-color-scheme: dark) {
            // @include ap-theme(m3-theme.$dark-theme, true, true); 
            color-scheme: dark;
            // @include use-dark-mode();
            @include ap-theme(m3-theme.$dark-theme, true);
        }

        @media not all and (prefers-color-scheme: dark) {
            @include ap-theme(m3-theme.$light-theme, false);
        }
    }
}