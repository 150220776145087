@use "@angular/material" as mat;

@mixin ap-renamemachinenames-dialog-theme($theme, $primary, $accent, $darkmode) {
    .active-text {
        color: $primary;
    }

    .warn-text {
        color: $accent;
    }
}

.replace-table-row {
    vertical-align: baseline;
}

.dialog-warning {
    max-width: 58%;
    margin-right: 10px;
}