@use '@angular/material' as mat;

@mixin ap-energy-widget-theme($theme, $primary, $accent, $darkmode) {
    @if $darkmode {

        .widg-box {
            background-color: mat.get-theme-color($theme, neutral, 30);
        }

        .widg-title,
        .widg-secondary {
            color: #bdbdbd;
        }

        .widg-main {
            color: #F2F2F2;
        }
    }

    @else {

        .widg-box {
            background-color: mat.get-theme-color($theme, neutral, 70);
        }

        .widg-title,
        .widg-secondary {
            color: #575757;
        }

        .widg-main {
            color: $primary;
        }
    }
}

.energy-widg {
    text-align: center;
}

.widg-title {
    font-weight: bold;
    font-size: 12px;
}

.widg-frame {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 50%;
    display: inline-block;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
}

.widg-box {
    border-radius: 10px;
    margin: 5px auto 5px auto;
    height: 120px;
    max-width: 350px;
    display: block;
}

.widg-main {
    font-size: 55px;
    padding-top: 14px;
    line-height: 1.42857143;
}

.widg-main-small {
    font-size: 35px;
    padding-top: 25px;
    // height: 50px;
}

.widg-secondary-small {
    padding-top: 10px;
}