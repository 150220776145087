@use '@angular/material' as mat;

@mixin ap-reporting-theme($theme, $primary, $accent, $darkmode) {

    // .error-reason, .missing-items-link, .missing-items-link:hover {
    //     @if $darkmode {
    //         color: lighten($accent, 20);
    //     } @else {
    //         color: $accent;
    //     }
    // }

    .mat-expansion-panel-header.mat-expanded.missing-roasts:not(.reconciled),
    .mat-expansion-panel-header.mat-expanded.missing-roasts:not(.reconciled):focus,
    .mat-expansion-panel-header.mat-expanded.missing-roasts:not(.reconciled):hover {
        border: 2px solid $accent;
    }

    // header is grey already ...
    // .mat-expansion-panel-header.mat-expanded.reconciled:not(.missing-roasts),
    // .mat-expansion-panel-header.mat-expanded.reconciled:not(.missing-roasts):focus,
    // .mat-expansion-panel-header.mat-expanded.reconciledroasts:not(.missing-roasts):hover {
    //     border: 2px solid mat.get-color-from-palette($grey-palette, 300);
    // }
    // .mat-expansion-panel-header.missing-roasts:not(.reconciled)::before {
    .mat-expansion-panel.missing-roasts:not(.reconciled)::before {
        background: $accent;
        z-index: 1;
    }

    // .mat-expansion-panel .mat-expansion-panel-header.mat-expanded.reconciled,
    // .mat-expansion-panel .mat-expansion-panel-header.mat-expanded.reconciled:focus,
    // .mat-expansion-panel .mat-expansion-panel-header.mat-expanded.reconciled:hover {
    //         background: mat.get-color-from-palette($grey-palette, 300);
    // }
    // .mat-expansion-panel-header.reconciled::before {
    //     position: absolute;
    //     bottom: 0;
    //     top: 0;
    //     left: 0px;
    //     content: "";
    //     background: mat.get-color-from-palette($grey-palette, 300);
    //     width: 13px;
    // }
}