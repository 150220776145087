@-moz-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate(0, -100%);
        transform: translate(0, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@-o-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate(0, -100%);
        transform: translate(0, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate(0, -100%);
        transform: translate(0, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate(0, -100%);
        transform: translate(0, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.fadeInDown {
    // -webkit-animation-name: fadeInDown;
    // animation-name: fadeInDown;
    -webkit-animation: fadeInDown 1s ease 0.3s 1 normal both;
    /* Safari 4+ */
    -moz-animation: fadeInDown 1s ease 0.3s 1 normal both;
    /* Fx 5+ */
    -o-animation: fadeInDown 1s ease 0.3s 1 normal both;
    /* Opera 12+ */
    animation: fadeInDown 1s ease 0.3s 1 normal both;
    /* IE 10+, Fx 29+ */

    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@keyframes pulse {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        color: white;
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.5);
        color: white;
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes pulse {
    from {
        -moz-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -moz-transform: scale(1.5);
        transform: scale(1.2);
        color: white;
    }

    to {
        -moz-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        color: white;
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulse2 {
    from {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    to {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes fadeInDownAlert {
    from {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@-o-keyframes fadeInDownAlert {
    from {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@-webkit-keyframes fadeInDownAlert {
    from {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@keyframes fadeInDownAlert {
    from {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

.fadeInDownAlert {
    // -webkit-animation-name: fadeInDown;
    // animation-name: fadeInDown;
    -webkit-animation: fadeInDownAlert 0.5s ease 0.2s 1 normal both;
    /* Safari 4+ */
    -moz-animation: fadeInDownAlert 0.5s ease 0.2s 1 normal both;
    /* Fx 5+ */
    -o-animation: fadeInDownAlert 0.5s ease 0.2s 1 normal both;
    /* Opera 12+ */
    animation: fadeInDownAlert 0.5s ease 0.2s 1 normal both;
    /* IE 10+, Fx 29+ */

    -webkit-animation-name: fadeInDownAlert;
    animation-name: fadeInDownAlert;
}


@-moz-keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }
}

@-o-keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
    }
}

.fadeOutUp {
    -moz-animation: fadeOutUp 1s ease 0.3s 1 normal both;
    -o-animation: fadeOutUp 1s ease 0.3s 1 normal both;
    -webkit-animation: fadeOutUp 1s ease 0.3s 1 normal both;
    animation: fadeOutUp 1s ease 0.3s 1 normal both;
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}