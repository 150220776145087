@use '@angular/material' as mat;

// @import 'material-icons/iconfont/outlined.scss';
// @import '@material-design-icons/font/outlined.scss';
@import '@material-symbols/font-400/outlined.scss';

@mixin ap-styles-theme($theme, $primary, $accent, $darkmode) {

    // apply color variants for some components
    .primary-element {
        @include mat.button-color($theme, $color-variant: primary);
        @include mat.icon-color($theme, $color-variant: primary);
        @include mat.radio-color($theme, $color-variant: primary);
        @include mat.checkbox-color($theme, $color-variant: primary);
        @include mat.slide-toggle-color($theme, $color-variant: primary);
        @include mat.progress-spinner-color($theme, $color-variant: primary);
    }

    .tertiary-element {
        @include mat.icon-color($theme, $color-variant: tertiary);
        @include mat.checkbox-color($theme, $color-variant: tertiary);
    }

    .primary-badge {
        @include mat.badge-color($theme, $color-variant: tertiary);
    }

    $primary-dark: mat.get-theme-color($theme, primary, 35);
    $primary-bright: mat.get-theme-color($theme, primary, 80);
    $primary-verybright: mat.get-theme-color($theme, primary, 95);

    $accent-bright: lighten($accent, 20);

    // BEGIN added for m3
    --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    --mdc-checkbox-selected-icon-color: #{$primary-dark};

    .appbackground {
        background-color: var(--mat-app-background-color);
    }

    // mat-checkbox.tertiary-element .mdc-checkbox__background {
    //     background-color: $accent;
    //     border-color: $accent;
    // }
    // END added for m3

    *:focus-visible {
        outline-color: $primary
    }

    .ngx-slider-limit {
        color: $primary-dark !important;
    }

    ngx-slider[disabled].inverted-slider .ngx-slider-bar {
        background: #8b91a2 !important;
    }

    .alert.alert-danger {
        background-color: #9e4c4c;
        color: #f9dcdb;
    }

    .alert.alert-danger a {
        color: mat.get-theme-color($theme, primary, 80);
    }

    a .scheduler-unit {
        color: $primary;
    }

    --scheduler-planner-placeholder-background: #{$primary-bright};
    --accent-color: #{$accent};
    --primary-color: #{$primary};


    @if $darkmode {
        --primary-color: #{$primary-dark};

        --cropper-outline-color: 'black';
        --cropper-overlay-color: 'black';

        .deleted-transaction,
        .has-error,
        .warn-amount,
        .scheduler-input-group .warn-amount .scheduler-input-number-input,
        .scheduler-input-group .warn-amount .scheduler-unit,
        .scheduler-list-header a.warn-amount,
        .scheduler-filter-hints .warn-amount,
        .scheduler-filter-hints .warn-amount .scheduler-unit,
        .scheduler-filter-hints .warn-amount a,
        .scheduler-filter-hints .warn-amount,
        .scheduler-filter-hints .warn-amount a,
        .discarded,
        .no-amount,
        .negative-amount,
        .negative-amount a,
        .replace-comment,
        .error-reason,
        .error-reason:hover,
        .missing-items-link,
        .missing-items-link:hover,
        .incomplete-value {
            color: $accent-bright !important;
        }

        .alert.alert-success {
            background-color: mat.get-theme-color($theme, primary, 40);
            color: mat.get-theme-color($theme, primary, 90);
        }

        .ap-popuplike {
            background-color: #464646;
            border-color: black;
        }

        .sort-indicator .mat-icon {
            color: rgba(255, 255, 255, 0.54);
        }

        .filter-input {
            // background-color: mat.get-color-from-palette($grey-palette, 300);
            caret-color: white;
        }

        .split-canvas as-split div.as-split-gutter {
            background-color: mat.get-theme-color($theme, neutral, 40);
        }

        img {
            opacity: 0.75;
            transition: opacity 0.5s ease-in-out;
        }

        img:hover {
            opacity: 1;
        }

        .back img {
            filter: brightness(0.85);
            transition: filter 0.5s ease-in-out;
        }

        .back img:hover {
            filter: none;
        }

        a .scheduler-unit {
            color: $primary-bright;
        }

        a {
            color: mat.get-theme-color($theme, primary, 80);
        }

        a:hover,
        a:focus {
            color: mat.get-theme-color($theme, primary, 70);
        }

        // snacks are inverse light/dark mode
        a.snack-link {
            color: mat.get-theme-color($theme, primary, 40);
        }

        a.snack-link:hover,
        a.snack-link:focus {
            color: mat.get-theme-color($theme, primary, 30);
        }

        .twoLineOption .mdc-list-item__primary-text {
            color: mat.get-theme-color($theme, neutral, 90);
        }

        .cdk-overlay-container .mat-mdc-select-panel {
            background: #5c5c5c;
        }

        .avatar-icon:hover,
        .avatar-icon-generic:hover,
        .contact-image:hover,
        .coffee-image:hover,
        .blend-image:hover,
        .store-image:hover {
            opacity: 1;
            cursor: pointer;
        }

        .table-badge {
            background-color: $primary-dark;
            border-color: $primary-dark;
        }

        .mat-mdc-card-avatar,
        .contact-avatar {
            background-color: mat.get-theme-color($theme, neutral, 30);
        }

        --scheduler-day-top-text: rgba(255, 255, 255, 0.8);
        --scheduler-darker-text: darken(rgba(255, 255, 255, 0.8), 20%);
        --scheduler-day-background: var(--mat-expansion-container-background-color);
        --scheduler-day-background-top: #{mat.get-theme-color($theme, neutral, 30)};
        --scheduler-day-background-top-today: #{mat.get-theme-color($theme, neutral, 40)};
        --scheduler-list-synced-item-background: #{mat.get-theme-color($theme, neutral, 40)};
        --scheduler-list-item-color: var(--mdc-filled-text-field-input-text-color);

        --scheduler-editbox-first: lighten(var(--scheduler-day-background), 20);
        --scheduler-editbox-rest: var(--scheduler-day-background);
        --scheduler-input-back-highlight: var(--scheduler-day-background);

        --scheduler-chip-selected-background: var(--scheduler-list-item-background);

        --mdc-switch-unselected-track-color: var(--scheduler-day-background-top-today);
        --mdc-switch-unselected-focus-track-color: var(--scheduler-day-background-top-today);
        --mdc-switch-unselected-hover-track-color: var(--scheduler-day-background-top-today);
        --mdc-switch-unselected-pressed-track-color: var(--scheduler-day-background-top-today);

        // TODO m3; check; was foreground, secondary-text
        --white-on-primary: #{mat.get-theme-color($theme, on-primary)};

        // added for m3
        --mat-datepicker-calendar-container-background-color: #{mat.get-theme-color($theme, neutral, 25)};
        --mat-select-panel-background-color: #{mat.get-theme-color($theme, neutral, 25)};
        --mat-autocomplete-background-color: #{mat.get-theme-color($theme, neutral, 25)};
        --mat-outlined-card-outline-color: #4d5c65;

        // --scheduler-list-item-background: #{mat.get-color-from-palette($grey-palette, 300)};
        // --scheduler-list-final-item-background: #{$primary-dark};

        // .scheduler-list-item {
        //     border: none;
        // }

        // .scheduler-list-item-border.selected {
        //     border-color: white;
        // }
    }

    @else {
        --cropper-outline-color: 'white';
        --cropper-overlay-color: 'white';

        .deleted-transaction,
        .has-error,
        .warn-amount,
        .scheduler-input-group .warn-amount .scheduler-input-number-input,
        .scheduler-input-group .warn-amount .scheduler-unit,
        .scheduler-list-header a.warn-amount,
        .scheduler-filter-hints .warn-amount,
        .scheduler-filter-hints .warn-amount .scheduler-unit,
        .scheduler-filter-hints .warn-amount a,
        .discarded,
        .no-amount,
        .negative-amount,
        .negative-amount a,
        .replace-comment,
        .error-reason,
        .error-reason:hover,
        .missing-items-link,
        .missing-items-link:hover,
        .incomplete-value {
            color: $accent !important;
        }

        .alert.alert-success {
            background-color: mat.get-theme-color($theme, primary, 95);
            color: mat.get-theme-color($theme, primary, 40);
        }

        .ap-popuplike {
            background-color: #bdbdbd;
            border-color: white;
        }

        // .filter-input {
        //     background-color: white;
        // }

        .sort-indicator .mat-icon {
            color: rgba(0, 0, 0, 0.54);
        }

        a {
            color: mat.get-theme-color($theme, primary, 40);
        }

        a:hover,
        a:focus {
            color: mat.get-theme-color($theme, primary, 30);
        }

        .twoLineOption .mdc-list-item__primary-text {
            color: black;
        }

        .avatar-icon:hover,
        .avatar-icon-generic:hover,
        .contact-image:hover,
        .coffee-image:hover,
        .blend-image:hover,
        .store-image:hover {
            opacity: 0.5;
            cursor: pointer;
        }

        .mat-expansion-panel-header.mat-expanded .coupon-box,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded:focus .coupon-box,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded:hover .coupon-box {
            border: 5px dotted #ffffff;
            background-color: #ffffff40;
        }

        .table-badge {
            background-color: $primary-bright;
            border-color: $primary-bright;
        }

        .mat-mdc-card-avatar,
        .contact-avatar {
            background-color: mat.get-theme-color($theme, neutral, 60);
        }

        --scheduler-day-top-text: rgba(0, 0, 0, 0.6);
        --scheduler-darker-text: darken(rgba(0, 0, 0, 0.6), 20%);
        --scheduler-darker-text: darken(rgba(255, 255, 255, 0.8), 20%);
        --scheduler-day-background: #{mat.get-theme-color($theme, neutral, 80)};
        --scheduler-day-background-top: #{mat.get-theme-color($theme, neutral, 60)};
        --scheduler-day-background-top-today: #{mat.get-theme-color($theme, neutral, 50)};
        --scheduler-list-synced-item-background: #{mat.get-theme-color($theme, neutral, 35)};
        --scheduler-list-item-color: rgba(255, 255, 255, 0.8);
        ;

        --scheduler-editbox-first: var(--scheduler-day-background-top);
        --scheduler-editbox-rest: var(--scheduler-day-background-top);
        --scheduler-input-back-highlight: var(--scheduler-day-background);

        --scheduler-chip-selected-background: #{mat.get-theme-color($theme, primary, 80)};

        --white-on-primary: #{mat.get-theme-color($theme, neutral, 90)};

        // added for m3
        --mat-datepicker-calendar-container-background-color: white;
        --mat-select-panel-background-color: white;
        --mat-autocomplete-background-color: white;

        // --scheduler-list-item-background: #{mat.get-color-from-palette($grey-palette, 300)};
        // --scheduler-list-final-item-background: #{$primary-bright};

        // .scheduler-list-item-border.selected {
        //     border-color: $primary-dark;
        // }

        // .scheduler-list-item {
        //     border: solid 2px #aaa;
        // }

    }

    // __<<ngM2ThemingMigrationEscapedComment0>>__
    // mat-form-field.mat-focused span.mat-placeholder-required.mat-mdc-form-field-required-marker {
    //     color: $primary;
    // }

    a.disabled-link,
    .comment {
        color: mat.get-theme-color($theme, neutral, 40);
    }

    .smallicon:not(.tertiary-element) {
        color: mat.get-theme-color($theme, primary, 60);
    }

    .action-button.mat-mdc-raised-button:not([disabled]) {
        background-color: $primary-dark;
    }

    .edit-button:not([disabled]),
    .edit-button.mat-mdc-raised-button:not([disabled]) {
        background-color: $accent;
    }

    .overview {
        background-color: $primary-verybright;
    }

    .mat-mdc-snack-bar-action,
    .filter-hints,
    .checkbox-filter-filter-hints,
    .scheduler-filter-hints {
        color: $primary;
    }

    .mat-icon.helpicon {
        color: mat.get-theme-color($theme, neutral, 40);
    }

    .inverted-icon {
        fill: $accent;
    }

    .inactive {
        fill: mat.get-theme-color($theme, neutral, 40);
    }

    .warn-icon {
        fill: $accent;
        stroke: $accent;
    }

    .grey-icon {
        color: mat.get-theme-color($theme, neutral, 40);
    }

    /* range slider */
    // normal
    .ngx-slider .ngx-slider-bar {
        background: mat.get-theme-color($theme, neutral, 90);
        height: 5px;
    }

    .ngx-slider .ngx-slider-selection {
        background: $primary-dark;
        // top: 14px;
        height: 5px;
    }

    .ngx-slider .ngx-slider-tick {
        background: mat.get-theme-color($theme, neutral, 40);
    }

    .ngx-slider .ngx-slider-tick.ngx-slider-selected {
        background: $primary-dark;
    }

    // inverted
    .inverted-slider.ngx-slider .ngx-slider-bar {
        background: $primary-dark;
        // top: 14px;
        height: 5px;
    }

    .inverted-slider.ngx-slider .ngx-slider-selection {
        background: mat.get-theme-color($theme, neutral, 90);
        height: 5px;
        // border-top: white 2px solid;
        // border-bottom: white 2px solid;
    }

    .inverted-slider.ngx-slider .ngx-slider-tick {
        background: $primary-dark;
    }

    .inverted-slider.ngx-slider .ngx-slider-tick.ngx-slider-selected {
        background: mat.get-theme-color($theme, neutral, 40);
    }

    .ngx-slider .ngx-slider-pointer {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        // top: auto; __<<ngM2ThemingMigrationEscapedComment2>>__
        top: -8px;
        bottom: 0;
        background-color: $primary-dark;
        // border-top-left-radius: 3px;
        // border-top-right-radius: 3px;
    }

    .ngx-slider .ngx-slider-pointer::after {
        display: none;
    }

    .ngx-slider .ngx-slider-pointer::before {
        content: "+";
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-family: courier;
        color: #ddd;
    }

    .ngx-slider .ngx-slider-bubble {
        bottom: 10px;
        font-size: 14px;
        // TODO m3 was foreground, text
        color: mat.get-theme-color($theme, neutral, 30);
    }

    .ngx-slider .ngx-slider-limit {
        // font-weight: bold;
        color: $primary-dark;
    }

    .ngx-slider .ngx-slider-tick {
        width: 1px;
        height: 10px;
        margin-left: 4px;
        border-radius: 0;
        top: 0;
    }

    .scheduler-planner-main {
        border-color: $primary-bright;
    }

    .scheduler-list-item-delete,
    .scheduler-list-item-progress:not(.scheduler-list-item-progress-placeholder) {
        background: $accent;
    }

    --scheduler-list-item-background: #{$primary-dark};

    .mat-mdc-chip-selected .chips-filter-filter-hints.warn-amount {
        color: $accent-bright;
    }

    .newroastreport-main-content {
        background-color: var(--mat-app-background-color);
        position: relative;
        z-index: 1;
    }
}