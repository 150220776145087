@mixin ap-store-theme($theme, $primary, $accent, $darkmode) {
    .greyicon {
        @if $darkmode {
            color: #ffffff50;
        }

        @else {
            color: #00000050;
        }
    }
}