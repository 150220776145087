@use '@angular/material' as mat;

@mixin ap-matexpandable-theme($theme, $primary, $accent, $darkmode) {

    @if $darkmode {

        .mat-expansion-panel-header.mat-expanded,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded:focus,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded:hover {
            background-color: mat.get-theme-color($theme, neutral, 30);
        }

        .head-label {
            color: mat.get-theme-color($theme, primary, 80);
        }

        .mat-expanded .head-label {
            color: inherit;
        }

        table.proptable tr:nth-child(odd) {
            // background-color: lighten(mat.m2-get-color-from-palette(map-get($theme, background), background), 20%);
            background-color: mat.get-theme-color($theme, neutral, 30);
        }

        table.proptable tr.inputrow td:not(.labelname) {
            background: none;
        }

        table.proptable input {
            background: none;
            border: mat.get-theme-color($theme, neutral, 40) 1px solid;
        }

        .head-stock-line-unitamount:not(.negative-amount) {
            color: mat.get-theme-color($theme, primary, 80);
        }
    }

    @else {

        .mat-expansion-panel-header.mat-expanded,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded:focus,
        .mat-expansion-panel .mat-expansion-panel-header.mat-expanded:hover {
            background-color: mat.get-theme-color($theme, primary, 80);
        }

        .head-label {
            color: mat.get-theme-color($theme, primary, 30);
        }

        .mat-expanded .head-label {
            color: white;
        }

        .mat-expanded.reconciled .head-label,
        .mat-expanded.missing-roasts .head-label {
            color: mat.get-theme-color($theme, primary, 30);
        }

        table.proptable tr:nth-child(odd) {
            background-color: darken(white, 10%);
        }

        table.proptable tr.inputrow td:not(.labelname) {
            background-color: white;
        }

        table.proptable input {
            border: #eeeeee 1px solid;
        }

        .head-stock-line-unitamount:not(.negative-amount) {
            color: mat.get-theme-color($theme, primary, 30);
        }
    }
}