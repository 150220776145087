@use "@angular/material" as mat;

@mixin ap-reminders-theme($theme, $primary, $accent, $darkmode) {
    @if $darkmode {
        .condition-box {
            background-color: mat.get-theme-color($theme, neutral, 30);
        }

        .animated-item {
            background-color: mat.get-theme-color($theme, neutral, 98);
        }

        .inactive-reminder {
            color: rgba(255, 255, 255, 0.54);
        }
    }

    @else {
        .condition-box {
            background-color: mat.get-theme-color($theme, primary, 95);
        }

        .animated-item {
            background-color: white;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            // background-color: mat.get-color-from-palette(map-get($theme, primary), 300);
        }

        .inactive-reminder {
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .expired-reminder.mat-expanded::before {
        border-bottom-left-radius: 3px;
    }

    .expired-reminder:not(.inactive-reminder)::before {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0px;
        content: "";
        width: 13px;
        background: $accent;
        z-index: 1;
    }

    .expired-reminder.inactive-reminder,
    .expired-reminder.inactive-reminder:focus,
    .expired-reminder.inactive-reminder:hover,
    .expired-reminder.mat-expanded,
    .expired-reminder.mat-expanded:focus,
    .expired-reminder.mat-expanded:hover {
        border: none;
    }

    .expired-reminder,
    .expired-reminder:focus,
    .expired-reminder:hover {
        border: 2px solid $accent;
    }

    .reminder-condition-expired:not(.inactive-reminder) {
        color: $accent;
    }

    .snooze-label {
        color: $primary;
    }
}