@media print {

    app-ap-toolbar,
    mat-sidenav.mat-sidenav,
    div.footer,
    div.actionbar,
    mat-icon.mat-icon.helpicon {
        display: none !important;
    }

    mat-expansion-panel-header.mat-expansion-panel-header {
        border-bottom: black dotted 1px !important;
    }

    a[href]::after {
        content: none !important;
    }

    // rest taken from https://github.com/angular/material.angular.io/issues/333
    // Force to print colors, otherwise the material components will look white
    * {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    body {
        // Roboto not printing ok on Chrome, fallback on supported fonts
        font-family: "Helvetica Neue", sans-serif !important;

        mat-sidenav-container {
            // Remove background color to save toner
            background-color: transparent !important;
            // Override the color from the template
            color: black !important;
            // Position needs to be relative
            position: relative !important;

            mat-sidenav-content {
                // Remove the content margin if sidenav is expanded when hidden for print
                margin: 0 !important;

                // Remove max width and padding from containers to flow text depending on printer page sizes
                .container {
                    min-width: 0 !important;
                    padding: 0 !important;
                }

                // Roboto not printing ok on Chrome, fallback on supported fonts
                .mat-typography,
                .mat-mdc-card {
                    font-family: "Helvetica Neue", sans-serif !important;

                    h1,
                    h2,
                    h3,
                    h4,
                    h5 {
                        font-family: "Helvetica Neue", sans-serif !important;
                    }
                }

                .mat-mdc-button,
                .mat-mdc-raised-button,
                .mat-mdc-icon-button,
                .mat-mdc-outlined-button,
                .mat-mdc-unelevated-button,
                .mat-mdc-fab,
                .mat-mdc-mini-fab {
                    font-family: "Helvetica Neue", sans-serif !important;
                }

                // Define where a page break should occur when printing
                h2 {
                    page-break-before: always; // always break before h2 elements, for chapters for example
                }

                h3,
                h4 {
                    page-break-after: avoid; // avoid breaking after subtitles, it looks weird
                }

                pre,
                blockquote,
                img {
                    page-break-inside: avoid; // avoid breaking inside pre and blockquotes
                }
            }
        }
    }
}